import { api } from "../api";

export const groupings = {
  state: [], // initial state
  reducers: {
    // handle state changes with pure functions
    update(state, payload) {
      return payload;
    },
    add(state, payload) {
      return [...state, payload];
    }
  },
  effects: dispatch => ({
    async getAll(payload, rootState) {
      const groupings = await api.get("/groupings");
      dispatch.groupings.update(groupings.data);
    },
    async addGrouping(payload, rootState) {
      const newGrouping = await api.post("/groupings", payload);
      dispatch.groupings.add(newGrouping.data);
    }
  })
};
