import { api } from '../api';

export const taskbooks = {
  state: {
    all: [],
    byId: {},
    userTaskBooks: {}
  }, // initial state
  reducers: {
    // handle state changes with pure functions
    updateAll(state, payload) {
      return { ...state, all: payload };
    },
    updateOne(state, payload) {
      return { ...state, byId: { ...state.byId, [payload.id]: payload } };
    },
    deleteOne(state, payload) {
      return {
        all: state.all.filter(tb => tb.id !== payload),
        byId: { ...state.byId, [payload]: undefined }
      };
    },
    addUserTaskBook(state, payload) {
      return {
        ...state,
        userTaskBooks: {
          ...state.userTaskBooks,
          [payload.userId]: {
            ...state.userTaskBooks[payload.userId],
            [payload.id]: payload.userTaskBook
          }
        }
      };
    },
    toggleTaskCompleted(state, payload) {
      let newGroups = state.userTaskBooks[payload.userId][payload.id].groups.map(group => {
        let newTasks = group.tasks.map(task => {
          if (task.id === payload.taskId) {
            return {
              ...task,
              [payload.verify ? 'verifiedBy' : 'completedBy']: payload.result[
                payload.verify ? 'VerifiedBy' : 'CompletedBy'
              ],
              completedAt: payload.result.userTaskBookField.completedAt,
              value: payload.result.userTaskBookField.value,
              signatureUrl: payload.result.userTaskBookField.signatureUrl,
              verifiedAt: payload.result.userTaskBookField.verifiedAt,
              verifySignatureUrl: payload.result.userTaskBookField.verifySignatureUrl,
              attachments: payload.result.attachments
            };
          }

          return task;
        });

        return {
          ...group,
          tasks: newTasks
        };
      });

      return {
        ...state,
        userTaskBooks: {
          ...state.userTaskBooks,
          [payload.userId]: {
            ...state.userTaskBooks[payload.userId],
            [payload.id]: {
              ...state.userTaskBooks[payload.userId][payload.id],
              groups: newGroups
            }
          }
        }
      };
    }
  },
  effects: dispatch => ({
    async getAll(payload, rootState) {
      const taskbooks = await api.get(`/taskbooks`);
      dispatch.taskbooks.updateAll(taskbooks.data);
    },
    async get(payload, rootState) {
      const taskbook = await api.get('/taskbooks/' + payload.id);
      dispatch.taskbooks.updateOne(taskbook.data);

      return taskbook.data;
    },
    async assignUser(payload) {
      await api.post('/taskbooks/' + payload.taskBookId + '/users', {
        id: payload.id
      });
      dispatch.taskbooks.get({ id: payload.taskBookId });
    },
    async delete(payload) {
      await api.delete('/taskbooks/' + payload.id);
      dispatch.taskbooks.deleteOne(payload.id);
    },
    async toggleTask(payload) {
      const { id, userId, taskId, signature, comment, attachments } = payload;
      const patchResult = await api.patch(`/taskbooks/${id}/users/${userId}`, {
        taskBookFieldId: taskId,
        signature,
        comment,
        attachments
      });

      dispatch.taskbooks.toggleTaskCompleted({
        id,
        userId,
        taskId,
        result: patchResult.data
      });
    },
    async verifyTask(payload) {
      const { id, userId, taskId, signature, comment } = payload;
      const patchResult = await api.patch(`/taskbooks/${id}/users/${userId}`, {
        taskBookFieldId: taskId,
        signature,
        comment,
        verify: true
      });

      dispatch.taskbooks.toggleTaskCompleted({
        id,
        userId,
        taskId,
        result: patchResult.data,
        verify: true
      });
    },
    async getUserTaskBook(payload) {
      const { id, userId } = payload;
      const userTaskBook = await api.get(`/taskbooks/${id}/users/${userId}`);

      dispatch.taskbooks.addUserTaskBook({
        id,
        userId,
        userTaskBook: userTaskBook.data.taskBook
      });
    }
  })
};
