import { api } from "../api";

export const systemSettings = {
  state: {}, // initial state
  reducers: {
    // handle state changes with pure functions
    replace(state, payload) {
      return payload
        ? payload.reduce((prev, cur) => {
            prev[cur.setting] = cur.value;
            return prev;
          }, {})
        : {};
    },
    upsert(state, payload) {
      return { ...state, ...payload };
    }
  },
  effects: dispatch => ({
    async getAll(payload, rootState) {
      const settings = await api.get("/system-settings");
      dispatch.systemSettings.replace(settings.data);
    },
    async update(payload, rootState) {
      const { setting, value } = payload;
      const updatedSetting = await api.put("/system-settings/" + setting, {
        value
      });
      dispatch.systemSettings.upsert({
        [setting]: String(value)
      });
      return payload;
    }
  })
};
