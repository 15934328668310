import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  UPDATE_USER_STARTED,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  CONFIRM_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  LOGOUT_USER,
  SESSION_LOAD,
  USER_LOADED
} from '../actions';

const INIT_STATE = {
  user: localStorage.getItem('user'),
  loading: false,
  confirm: false,
  confirmEmail: '',
  error: ''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: true, error: null };
    case LOGIN_USER_SUCCESS:
      return { ...state, loading: false, user: action.payload.user };
    case LOGIN_USER_FAILURE:
      return { ...state, loading: false, error: action.payload.message };
    case USER_LOADED:
      return { ...state, loading: false, user: action.payload.user };
    case UPDATE_USER_STARTED:
      return { ...state, loading: true, error: null };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: { ...state.user, ...action.payload.user }
      };
    case UPDATE_USER_FAILURE:
      return { ...state, loading: false, error: action.payload.message };
    case CONFIRM_USER:
      return { ...state, confirm: true, confirmEmail: action.payload.email };
    case REGISTER_USER:
      return { ...state, loading: true };
    case REGISTER_USER_SUCCESS:
      return { ...state, loading: false, user: action.payload.uid };
    case LOGOUT_USER:
      return { ...state, user: null };
    case SESSION_LOAD:
      return { ...state, currentUser: action.payload.currentUser };
    default:
      return { ...state };
  }
};
