import { api } from "../api";

export const engagementCategories = {
  state: [], // initial state
  reducers: {
    // handle state changes with pure functions
    update(state, payload) {
      return payload;
    },
    add(state, payload) {
      return [...state, payload];
    }
  },
  effects: dispatch => ({
    async getAll(payload, rootState) {
      const engagementCategories = await api.get("/engagement-categories");
      dispatch.engagementCategories.update(engagementCategories.data);
    },
    async addCategory(payload, rootState) {
      const newCategory = await api.post("/engagement-categories", payload);
      dispatch.engagementCategories.add(newCategory.data);
    }
  })
};
