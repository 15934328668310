import { api } from "../api";

export const notifications = {
  state: [], // initial state
  reducers: {
    update(state, payload) {
      return payload;
    },
    markAsRead(state, payload) {
      const newState = [...state];
      return newState.map(n => {
        if (n.id === payload.id) {
          n.readAt = payload.readAt;
        }

        return n;
      });
    }
  },
  effects: dispatch => ({
    async getAll(payload, rootState) {
      const notifications = await api.get("/me/notifications");
      dispatch.notifications.update(notifications.data);
    },
    async markAsRead(notification, rootState) {
      if (notification.readAt) {
        return true;
      }

      const readAt = new Date();
      await api.patch("/notifications/" + notification.id, {
        readAt
      });

      dispatch.notifications.markAsRead({ id: notification.id, readAt });
    }
  })
};
