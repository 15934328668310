import { api } from "../api";

export const permissions = {
  state: [], // initial state
  reducers: {
    // handle state changes with pure functions
    update(state, payload) {
      return payload;
    },
    add(state, payload) {
      return [...state, payload];
    }
  },
  effects: dispatch => ({
    async getAll(payload, rootState) {
      const permissions = await api.get("/permissions");
      dispatch.permissions.update(permissions.data);
    },
    async addPermission(payload, rootState) {
      const newPermission = await api.post("/permissions", payload);
      dispatch.permissions.add(newPermission.data);
    }
  })
};
