import { api } from "../api";

export const engagementStatuses = {
  state: [], // initial state
  reducers: {
    // handle state changes with pure functions
    update(state, payload) {
      return payload;
    },
    add(state, payload) {
      return [...state, payload];
    }
  },
  effects: dispatch => ({
    async getAll(payload, rootState) {
      const engagementStatuses = await api.get("/engagement-statuses");
      dispatch.engagementStatuses.update(engagementStatuses.data);
    },
    async addStatus(payload, rootState) {
      const newStatus = await api.post("/engagement-statuses", payload);
      dispatch.engagementStatuses.add(newStatus.data);
    }
  })
};
