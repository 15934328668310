import { api } from "../api";

export const trainingTopics = {
  state: [], // initial state
  reducers: {
    // handle state changes with pure functions
    update(state, payload) {
      return payload;
    },
    add(state, payload) {
      return [...state, payload];
    }
  },
  effects: dispatch => ({
    async getAll(payload, rootState) {
      const trainingTopics = await api.get("/training-topics");
      dispatch.trainingTopics.update(trainingTopics.data);
    },
    async addTrainingTopic(payload, rootState) {
      const newTopic = await api.post("/training-topics", payload);
      dispatch.trainingTopics.add(newTopic.data);
    }
  })
};
