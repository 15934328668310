import { api } from '../api';

export const observationTemplates = {
  state: {
    all: [],
    byId: {}
  },
  reducers: {
    updateAll(state, payload) {
      return { ...state, all: payload };
    },
    updateOne(state, payload) {
      return { ...state, byId: { ...state.byId, [payload.id]: payload } };
    },
    deleteOne(state, payload) {
      return {
        all: state.all.filter(tb => tb.id !== payload),
        byId: { ...state.byId, [payload]: undefined }
      };
    }
  },
  effects: dispatch => ({
    async getAll(payload, rootState) {
      const observationTemplates = await api.get('/observation-templates');
      dispatch.observationTemplates.updateAll(observationTemplates.data);
    },
    async get(payload, rootState) {
      const observationTemplate = await api.get('/observation-templates/' + payload.id);
      dispatch.observationTemplates.updateOne(observationTemplate.data);

      return observationTemplate.data;
    },
    async delete(payload) {
      await api.delete('/observation-templates/' + payload.id);
      dispatch.observationTemplates.deleteOne(payload.id);
    }
  })
};
