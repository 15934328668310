import React from "react";
import { Input, FormFeedback } from "reactstrap";

const getPropValue = (obj, key) =>
  key.split(".").reduce((o, x) => (o == undefined ? o : o[x]), obj);

const ReactstrapFormikInput = ({
  field: { ...fields },
  form: { touched, errors, ...rest },
  ...props
}) => {
  const Component = props.component || Input;
  const invalid = Boolean(
    getPropValue(touched, fields.name) && getPropValue(errors, fields.name)
  );
  return (
    <div>
      <Component {...props} {...fields} invalid={invalid} />
      {getPropValue(touched, fields.name) &&
        getPropValue(errors, fields.name) && (
          <FormFeedback valid={!invalid}>
            {getPropValue(errors, fields.name)}
          </FormFeedback>
        )}
    </div>
  );
};
export default ReactstrapFormikInput;
