import { combineReducers } from "redux";

import {
  SWITCH_USER,
  UPDATE_PROFILE,
  DELETE_PROFILE,
  USER_FETCH_SUCCEEDED
} from "./actions";

function user(state = {}, action) {
  switch (action.type) {
    case SWITCH_USER:
      return action.user;
    default:
      return state;
  }
}

function profile(state = {}, action) {
  switch (action.type) {
    case UPDATE_PROFILE:
      return Object.assign({}, state, action.profile);
    case DELETE_PROFILE:
      return null;
    default:
      return state;
  }
}

function sysUser(state = {}, action) {
  switch (action.type) {
    case USER_FETCH_SUCCEEDED:
      return { ...state, user: action.user };
    default:
      return state;
  }
}

const authUser = combineReducers({
  user,
  profile,
  sysUser
});

export default authUser;
