import { api } from "../api";

export const locations = {
  state: [], // initial state
  reducers: {
    // handle state changes with pure functions
    update(state, payload) {
      return payload;
    },
    add(state, payload) {
      return [...state, payload];
    }
  },
  effects: dispatch => ({
    async getAll(payload, rootState) {
      const locations = await api.get("/locations");
      dispatch.locations.update(locations.data);
    },
    async addLocation(payload, rootState) {
      const newLocation = await api.post("/locations", payload);
      dispatch.locations.add(newLocation.data);
    }
  })
};
