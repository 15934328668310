import { api } from "../api";

export const taskBookGroups = {
  state: {
    byId: {},
    userTaskBooks: {}
  },
  reducers: {
    // handle state changes with pure functions
    updateAll(state, payload) {
      return { ...state, byId: payload };
    },
    updateOne(state, payload) {
      return { ...state, byId: { ...state.byId, [payload.id]: payload } };
    },
    deleteOne(state, payload) {
      return {
        byId: { ...state.byId, [payload]: undefined }
      };
    },
    addUserTaskBook(state, payload) {
      return {
        ...state,
        userTaskBooks: {
          ...state.userTaskBooks,
          [payload.userId]: {
            ...state.userTaskBooks[payload.userId],
            [payload.id]: payload.userTaskBook
          }
        }
      };
    },
    removeSkillSheetFromTaskBook(state, payload) {
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.taskBookId]: {
            ...state.byId[payload.taskBookId],
            SkillSheets: state.byId[payload.taskBookId].SkillSheets.filter(
              item => item.id !== payload.id
            )
          }
        }
      };
    }
  },
  effects: dispatch => ({
    async getAll(payload, rootState) {
      const taskBookGroups = await api.get("/taskbook-groups");
      dispatch.taskBookGroups.updateAll(taskBookGroups.data);
    },
    async get(payload, rootState) {
      const taskbook = await api.get("/taskbook-groups/" + payload.id);
      dispatch.taskBookGroups.updateOne(taskbook.data);
    },
    async create(payload, rootState) {
      const taskbook = await api.post("/taskbook-groups", payload);
      dispatch.taskBookGroups.updateOne(taskbook.data);
    },
    async update(payload, rootState) {
      const taskbook = await api.put("/taskbook-groups/" + payload.id, payload);
      dispatch.taskBookGroups.updateOne(taskbook.data);
    },
    async delete(payload) {
      const deleted = await api.delete("/taskbook-groups/" + payload.id);
      dispatch.taskBookGroups.deleteOne(payload.id);
    },
    async assignUser(payload) {
      await api.post("/taskbook-groups/" + payload.taskBookId + "/users", {
        userId: payload.id
      });
    },
    async assignSkillSheet(payload) {
      await api.post(
        "/taskbook-groups/" + payload.taskBookId + "/skillsheets",
        {
          skillSheetId: payload.id
        }
      );
    },
    async removeSkillSheet(payload) {
      await api.delete(
        "/taskbook-groups/" + payload.taskBookId + "/skillsheets/" + payload.id
      );
      dispatch.taskBookGroups.removeSkillSheetFromTaskBook(payload);
    },
    async getUserTaskBook(payload) {
      const { id, userId } = payload;
      const userTaskBook = await api.get(
        `/taskbook-groups/${id}/users/${userId}`
      );

      dispatch.taskBookGroups.addUserTaskBook({
        id,
        userId,
        userTaskBook: userTaskBook.data
      });
    }
  })
};
