import { api } from "../api";

export const feedbackPosts = {
  state: [], // initial state
  reducers: {
    // handle state changes with pure functions
    update(state, payload) {
      return payload;
    },
    updateStatus(state, payload) {
      return state.map(post => {
        if (post.id === payload.id) {
          return { ...post, status: payload.status };
        }
        return post;
      });
    },
    updateLikedStatus(state, payload) {
      return state.map(post => {
        if (post.id === payload.id) {
          const originalLikes = post.likes || [];
          const newLikes = payload.liked
            ? [...post.likes, payload.userId]
            : originalLikes.filter(like => like !== payload.userId);
          return { ...post, likedByUser: payload.liked, likes: newLikes };
        }
        return post;
      });
    },
    addComment(state, payload) {
      return state.map(post => {
        if (post.id === payload.id) {
          return { ...post, comments: [...post.comments, payload.comment] };
        }

        return post;
      });
    }
  },
  effects: dispatch => ({
    async getAll(payload, rootState) {
      const feedbackPosts = await api.get("/feedback-posts");
      dispatch.feedbackPosts.update(feedbackPosts.data);
    },
    async setStatus(payload, rootState) {
      await api.patch("/feedback-posts/" + payload.post.id, {
        status: payload.newStatus
      });

      dispatch.feedbackPosts.updateStatus({
        status: payload.newStatus,
        id: payload.post.id
      });
    },
    async like(payload, rootState) {
      await api.post("/feedback-posts/" + payload.post.id + "/likes");

      dispatch.feedbackPosts.updateLikedStatus({
        liked: true,
        id: payload.post.id,
        userId: rootState.auth.user.id
      });
    },
    async unlike(payload, rootState) {
      await api.delete("/feedback-posts/" + payload.post.id + "/likes");

      dispatch.feedbackPosts.updateLikedStatus({
        liked: false,
        id: payload.post.id,
        userId: rootState.auth.user.id
      });
    },
    async comment(payload, rootState) {
      const comment = await api.post(
        "/feedback-posts/" + payload.id + "/comments",
        {
          body: payload.comment
        }
      );

      dispatch.feedbackPosts.addComment({
        id: payload.id,
        comment: comment.data.comment
      });
    }
  })
};
