import { api } from "../api";

export const engagementAuditLogs = {
  state: {
    byEngagementId: {}
  },
  reducers: {
    replace(state, payload) {
      const stateCopy = { ...state };
      stateCopy.byEngagementId[payload.engagementId] = payload.logs;

      return stateCopy;
    }
  },
  effects: dispatch => ({
    async getByEngagementId(id) {
      const auditLog = await api.get("/engagements/" + id + "/audit-log");
      dispatch.engagementAuditLogs.replace({
        logs: auditLog.data,
        engagementId: id
      });
    }
  })
};
