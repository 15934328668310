import { api } from '../api';

export const skillSheetCategories = {
  state: [], // initial state
  reducers: {
    // handle state changes with pure functions
    update(state, payload) {
      return payload;
    },
    add(state, payload) {
      return [...state, payload];
    }
  },
  effects: dispatch => ({
    async getAll(payload, rootState) {
      const skillSheetCategories = await api.get('/skillsheet-categories');
      dispatch.skillSheetCategories.update(skillSheetCategories.data);
    },
    async addSkillSheetCategory(payload, rootState) {
      const newCategory = await api.post('/skillsheet-categories', payload);
      dispatch.skillSheetCategories.add(newCategory.data);
    }
  })
};
