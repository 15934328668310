import { api } from '../api';

export const observationReports = {
  state: {
    all: [],
    byId: {}
  }, // initial state
  reducers: {
    // handle state changes with pure functions
    updateAll(state, payload) {
      return { ...state, all: payload };
    },
    updateOne(state, payload) {
      return { ...state, byId: { ...state.byId, [payload.id]: payload } };
    },
    deleteOne(state, payload) {
      return {
        all: state.all.filter(tb => tb.id !== payload),
        byId: { ...state.byId, [payload]: undefined }
      };
    }
  },
  effects: dispatch => ({
    async getAll(payload, rootState) {
      const taskbooks = await api.get('/taskbooks');
      dispatch.taskbooks.updateAll(taskbooks.data);
    },
    async get(payload, rootState) {
      const taskbook = await api.get('/taskbooks/' + payload.id);
      dispatch.taskbooks.updateOne(taskbook.data);

      return taskbook.data;
    },
    async delete(payload) {
      await api.delete('/taskbooks/' + payload.id);
      dispatch.taskbooks.deleteOne(payload.id);
    }
  })
};
