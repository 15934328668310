const SWITCH_USER = "SWITCH_USER";
const UPDATE_PROFILE = "UPDATE_PROFILE";
const DELETE_PROFILE = "DELETE_PROFILE";
const LOAD_PROFILE = "LOAD_PROFILE";
const USER_FETCH_SUCCEEDED = "USER_FETCH_SUCCEEDED";

// when user sign in / out
function switchUser(user) {
  return {
    type: SWITCH_USER,
    user
  };
}

// when user update profile
function updateProfile(profile) {
  return {
    type: UPDATE_PROFILE,
    profile
  };
}

// when user sign out
function deleteProfile() {
  return { type: DELETE_PROFILE };
}

function loadProfile(user) {
  return {
    type: LOAD_PROFILE,
    user
  };
}

function userFetchSucceeded(user) {
  return {
    type: USER_FETCH_SUCCEEDED,
    user
  };
}

export {
  SWITCH_USER,
  UPDATE_PROFILE,
  DELETE_PROFILE,
  LOAD_PROFILE,
  USER_FETCH_SUCCEEDED
};
export {
  switchUser,
  updateProfile,
  deleteProfile,
  loadProfile,
  userFetchSucceeded
};
