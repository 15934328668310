import { api } from "../api";
import produce from "immer";

export const jobTitles = {
  state: [], // initial state
  reducers: {
    // handle state changes with pure functions
    update(state, payload) {
      return payload;
    },
    add: produce((state, payload) => {
      state.push(payload);
    })
  },
  effects: dispatch => ({
    async getAll(payload, rootState) {
      const jobTitles = await api.get("/job-titles");
      dispatch.jobTitles.update(jobTitles.data);
    },
    async addJobTitle(payload, rootState) {
      const newJobTitle = await api.post("/job-titles", payload);
      dispatch.jobTitles.add(newJobTitle.data);
    }
  })
};
