import { api } from '../api';

export const skillsheets = {
  state: {
    all: [],
    byId: {}
  },
  reducers: {
    updateAll(state, payload) {
      return { ...state, all: payload };
    },
    updateOne(state, payload) {
      return { ...state, byId: { ...state.byId, [payload.id]: payload } };
    }
  },
  effects: dispatch => ({
    async getAll(payload, rootState) {
      const skillsheets = await api.get('/taskbooks/skillsheets');
      dispatch.skillsheets.updateAll(skillsheets.data);
    },
    async get(payload, rootState) {
      const skillsheet = await api.get('/taskbooks/skillsheets/' + payload.id);
      dispatch.skillsheets.updateOne(skillsheet.data);

      return skillsheet.data;
    }
  })
};
