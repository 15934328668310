import { api } from "../api";

export const units = {
  state: [], // initial state
  reducers: {
    // handle state changes with pure functions
    update(state, payload) {
      return payload;
    },
    add(state, payload) {
      return [...state, payload];
    }
  },
  effects: dispatch => ({
    async getAll(payload, rootState) {
      const units = await api.get("/units");
      dispatch.units.update(units.data);
    },
    async addUnit(payload, rootState) {
      const newUnit = await api.post("/units", payload);
      dispatch.units.add(newUnit.data);
    }
  })
};
