import { api } from "../api";
import produce from "immer";
import moment from "moment";

export const engagements = {
  state: {
    byId: {},
    byUserId: {}
  },
  reducers: {
    update(state, payload) {
      const byId = {};
      const byUserId = {};
      payload.forEach(engagement => {
        byId[engagement.id] = engagement;
        if (typeof byUserId[engagement.User.id] === "undefined") {
          byUserId[engagement.User.id] = {};
        }
        byUserId[engagement.User.id][engagement.id] = engagement;
      });
      return {
        byId,
        byUserId
      };
    },
    append(state, payload) {
      const byId = {};
      const byUserId = {};
      payload.forEach(engagement => {
        byId[engagement.id] = engagement;
        if (typeof byUserId[engagement.User.id] === "undefined") {
          byUserId[engagement.User.id] = {};
        }
        byUserId[engagement.User.id][engagement.id] = engagement;
      });
      return {
        byId: { ...state.byId, ...byId },
        byUserId: { ...state.byUserId, ...byUserId }
      };
    },
    replace(state, payload) {
      const stateCopy = { ...state };
      stateCopy.byId[payload.id] = payload;
      if (typeof stateCopy.byUserId[payload.User.id] === "undefined") {
        stateCopy.byUserId[payload.User.id] = {};
      }
      stateCopy.byUserId[payload.User.id][payload.id] = payload;

      return stateCopy;
    },
    addComment: produce((state, { id, userId, comment }) => {
      state.byId[id].Comments.push(comment);
      state.byUserId[userId][id].Comments.push(comment);
    }),
    setInvestigation: produce((state, { id, userId, investigation }) => {
      state.byId[id].Investigation = investigation;
      state.byUserId[userId][id].Investigation = investigation;
    }),
    appendInvestigationEvent: produce((state, { id, userId, event }) => {
      state.byId[id].InvestigationEvents.push(event);
      state.byUserId[userId][id].InvestigationEvents.push(event);
    })
  },
  effects: dispatch => ({
    async getAll() {
      const engagements = await api.get("/engagements");
      dispatch.engagements.update(engagements.data);
    },
    async getById(payload, rootState) {
      const engagement = await api.get("/engagements/" + payload.id);
      dispatch.engagements.replace(engagement.data);
    },
    async getForUser(payload, rootState) {
      const userEngagements = await api.get(
        "/users/" + payload.userId + "/engagements/" + "?year=" + payload.year
      );
      dispatch.engagements.update(userEngagements.data);
    },
    async comment(payload, rootState) {
      const comment = await api.post(
        "/engagements/" + payload.id + "/comments",
        {
          comment: payload.comment,
          private: payload.private || false
        }
      );

      dispatch.engagements.addComment({
        id: payload.id,
        comment: comment.data.comment,
        userId: payload.userId
      });
    },
    async startInvestigation(payload) {
      const result = await api.post(
        "/engagements/" + payload.id + "/investigations"
      );

      dispatch.engagements.setInvestigation({
        id: payload.id,
        userId: payload.userId,
        investigation: result.data
      });
    },
    async addInvestigationEvent({
      id,
      userId,
      eventDescription,
      selectedDate
    }) {
      const result = await api.post(`/engagements/${id}/add-event`, {
        eventDescription,
        dateOfEvent: moment(selectedDate.format("MM-DD-YYYY"))
      });

      dispatch.engagements.appendInvestigationEvent({
        id,
        userId,
        event: result.data
      });
    }
  })
};
