import { api } from "../api";

export const trainingCategories = {
  state: [], // initial state
  reducers: {
    // handle state changes with pure functions
    update(state, payload) {
      return payload;
    },
    add(state, payload) {
      return [...state, payload];
    }
  },
  effects: dispatch => ({
    async getAll(payload, rootState) {
      const trainingCategories = await api.get("/training-categories");
      dispatch.trainingCategories.update(trainingCategories.data);
    },
    async addTrainingCategory(payload, rootState) {
      const newCategory = await api.post("/training-categories", payload);
      dispatch.trainingCategories.add(newCategory.data);
    }
  })
};
